<template>
  <div :key="this.openForm">
    <td
      class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end"
    >
      <a
        @click="$emit('duplicateList')"
        href="javascript:void(0)"
        :class="
          `p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`
        "
        title="Duplicate"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z"
          />
          <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
        </svg>
      </a>
      <a
        @click="$emit('deleteList')"
        href="javascript:void(0)"
        :class="
          `p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`
        "
        :title="$t('delete')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
      <button
        :class="
          `rounded p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`
        "
        @click="$emit('openEditForm')"
        :title="$t('edit')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
          />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </td>
  </div>
  <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import CreateNewRouting from "../components/CreateNewRouting.vue";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import HoverTooltip from "../components/HoverTooltip.vue";

const account = localStorage.getItem("account");

export default {
  name: "EditRoutingList",
  props: ["id", "openForm"],
  data() {
    return {
      openForm: true,
      account,
    };
  },
  components: {
    CreateNewRouting,
    ComfirmationDialog,
    HoverTooltip,
  },
  methods: {},
  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style></style>
