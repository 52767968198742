<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center card_height_position pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="rounded-md inline-block align-bottom bg-white  px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              {{ this.messageToShow }}
            </h3>
            <div class="mt-2" v-if="this.subMessageToShow">
              <p class="text-sm text-gray-500">{{ this.subMessageToShow }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid-flow-row-dense">
          <button
            @click="goBack ? goOneBack() : reloadPage()"
            type="button"
            class="rounded w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 body__header--button font-medium text-white focus:outline-none sm:col-start-2 sm:text-sm"
          >
            {{ $t("dialog.confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ConfirmationDialog",
  props: ["messageToShow", "subMessageToShow", "reload", "goBack"],
  methods: {
    reloadPage() {
      location.reload();
    },
    goOneBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.card_height_position {
  min-height: 100vh;
}
</style>
