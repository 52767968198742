<template>
 <!-- Routing form -->
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="max-w-7xl mx-auto 2xl:px-0 px-4">
   <form
    @submit.prevent="this.openUpdateForm ? putRouting(editData.id) : postRouting()"
    method="POST"
   >
    <div class="space-y-12">
     <div class="border-b border-gray-900/10 pb-12">
      <div class="pt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="col-span-3">
        <label for="list-name" class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("listName") }}*
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.listName"
          required
          type="text"
          name="list-name"
          id="list-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>

       <div class="col-span-3">
        <label for="geoModel" class="block text-sm font-semibold leading-6 text-gray-900">
         {{ $t("geoModel") }}*
        </label>
        <div class="mt-2">
         <select
          v-model="this.form.geoModel"
          required
          id="geoModel"
          name="geoModel"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         >
          <option value="1">{{ $t("frenchDepartments") }}</option>
         </select>
        </div>
       </div>
      </div>
     </div>

     <div class="border-b border-gray-900/10 pb-12">
      <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-3">
        <label for="fallback1" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("fallback1") }}
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.fallback1"
          type="text"
          name="fallback1"
          id="fallback1"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
       <div class="sm:col-span-3">
        <label for="fallback1" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("fallback2") }}
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.fallback2"
          type="text"
          name="fallback2"
          id="fallback2"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
       <div class="sm:col-span-3">
        <label for="fallback3" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("fallback3") }}
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.fallback3"
          type="text"
          name="fallback3"
          id="fallback3"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
       <div class="sm:col-span-3">
        <label for="fallback4" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("fallback4") }}
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.fallback4"
          type="text"
          name="fallback4"
          id="fallback4"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="mt-6 pb-12 flex items-center justify-end gap-x-6">
     <button
      type="button"
      @click="this.$emit('closeForm')"
      class="rounded-md bg-white px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold text-gray-900 hover:bg-gray-50"
     >
      {{ $t("ticket.cancel") }}
     </button>
     <button
      type="submit"
      class="rounded-md body__table--header px-3 py-2 text-sm font-semibold text-white shadow-sm"
     >
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import axios from "axios";

const account = localStorage.getItem("account");

export default {
 name: "CreateNewRouting",
 props: ["closeFormButton", "editData", "openUpdateForm"],
 components: {},
 data() {
  return {
   account,
   closeForm: true,
   form: {
    listName: "",
    geoModel: "",
    fallback1: "",
    fallback2: "",
    fallback3: "",
    fallback4: "",
   },
  };
 },

 methods: {
  postRouting() {
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists`,
    params: {
     customerAccount: this.account,
     listName: this.form.listName,
     geographicModel: this.form.geoModel,
     fallBackDestination1: this.form.fallback1,
     fallBackDestination2: this.form.fallback2,
     fallBackDestination3: this.form.fallback3,
     fallBackDestination4: this.form.fallback4,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     let msg = this.$t("elementCreatedSuccessfully", { element: this.$t("routing") });
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);

     this.$router.push({
      name: "RoutingListDetailExpert3cx",
      params: { account: this.account, id: res.data.id },
     });
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  putRouting(id) {
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${id}`,
    params: {
     customerAccount: this.account,
     listName: this.form.listName,
     geographicModel: this.form.geoModel,
     fallBackDestination1: this.form.fallback1,
     fallBackDestination2: this.form.fallback2,
     fallBackDestination3: this.form.fallback3,
     fallBackDestination4: this.form.fallback4,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     let msg = this.$t("elementUpdatedSuccessfully", { element: this.$t("routing") });
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
     this.$emit("closeForm");
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
 },
 mounted() {
  if (this.openUpdateForm) {
   this.form.listName = this.editData.listName;
   this.form.geoModel = this.editData.geographicModel;
   this.form.fallback1 = this.editData.fallBackDestination1;
   this.form.fallback2 = this.editData.fallBackDestination2;
   this.form.fallback3 = this.editData.fallBackDestination3;
   this.form.fallback4 = this.editData.fallBackDestination4;
  }
 },
};
</script>

<style></style>
