<template>
  <span class="tooltiptext text-sm">{{ this.text }}</span>
</template>

<script>
export default {
  name: "HoverTooltip",
  props: ["text"],
};
</script>

<style>
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: rgb(167, 167, 167);
  color: #fff;
  text-align: center;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 20;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
